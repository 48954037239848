<template>
  <div class="csfg__navbar">
    <span
      v-on:click="$emit('choseMode', mode.action)"
      :class="[
        'csfg__navbar__button',
        { 'csfg__navbar__button--active': chosenMode == mode.action },
      ]"
      v-for="mode in navigation"
      :key="mode.action"
      v-html="mode.name"
    >
    </span>
  </div>
</template>
<script>
import { navigation } from "../config.js";

export default {
  name: "NewNavbar",
  data() {
    return {
      navigation,
    };
  },
  props: ["chosenMode"],
};
</script>
<style lang="scss">
.csfg__navbar {
  background: #f5f5f5 0 0 no-repeat padding-box;
  border-radius: 25px;
  @media (min-width: 780px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0 0;
  }
  &__button {
    cursor: pointer;
    padding: 7px 15px 7px 15px;
    font-weight: 700;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.502);
    &:hover {
      color: rgba(0, 0, 0, 0.404);
    }
    &--active {
      background: #810d60 0 0 no-repeat padding-box;
      border-radius: 25px;
      color: #f5f5f5;
      &:hover {
        color: #b5b5b5;
      }
    }
  }
}
</style>
