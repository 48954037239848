<template>
  <div
    :class="[
      'csfg__control',
      `csfg__control--mode${chosenMode}`,
      { 'csfg__control--active': active },
    ]"
    v-if="state"
  >
    <span
      :class="[
        'csfg__control__item',
        'csfg__control__item--previous',
        { 'csfg__control__item--active': state.image > 0 },
      ]"
      v-if="modeOptions[chosenMode].includes(1)"
      v-on:click="changeImage('-')"
    >
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        style="enable-background: new 0 0 283.5 283.5"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M44.4,9.2H239c19.4,0,35.2,15.8,35.2,35.2V239c0,19.4-15.8,35.2-35.2,35.2H44.4C25,274.2,9.2,258.4,9.2,239
	V44.4C9.2,25,25,9.2,44.4,9.2z M239,262.8c13.2,0,23.9-10.7,23.9-23.9V44.4c0-13.2-10.7-23.9-23.9-23.9H44.4
	c-13.2,0-23.9,10.7-23.9,23.9V239c0,13.2,10.7,23.9,23.9,23.9H239z M182.4,48.1l-89.5,89.5c-2.2,2.2-2.2,5.8,0,8l89.5,89.5
	c2.2,2.2,5.8,2.2,8,0s2.2-5.8,0-8l-85.5-85.5l85.5-85.5c2.2-2.2,2.2-5.8,0-8c-1.1-1.1-2.6-1.7-4-1.7S183.5,47,182.4,48.1z"
        />
      </svg>
    </span>
    <span
      :class="[
        'csfg__control__item',
        'csfg__control__item--play',
        { 'csfg__control__item--active': state.play },
      ]"
      v-if="modeOptions[chosenMode].includes(2)"
      v-on:click="state.play = !state.play"
    >
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        style="enable-background: new 0 0 283.5 283.5"
        xml:space="preserve"
        v-if="!state.play"
      >
        <path
          class="st0"
          d="M239,274.2H44.4C25,274.2,9.2,258.4,9.2,239V44.4C9.2,25,25,9.2,44.4,9.2H239c19.4,0,35.2,15.8,35.2,35.2V239
	C274.2,258.4,258.4,274.2,239,274.2z M44.4,20.6c-13.2,0-23.9,10.7-23.9,23.9V239c0,13.2,10.7,23.9,23.9,23.9H239
	c13.2,0,23.9-10.7,23.9-23.9V44.4c0-13.2-10.7-23.9-23.9-23.9H44.4z M82,229.5c-0.9,0-1.8-0.2-2.6-0.7c-1.9-1-3-2.9-3-5V59.7
	c0-2.1,1.2-4,3-5c1.9-1,4.1-0.8,5.9,0.3l119.4,82c1.5,1.1,2.5,2.8,2.5,4.7c0,1.9-0.9,3.6-2.5,4.7l-119.4,82
	C84.3,229.1,83.2,229.5,82,229.5z M87.7,70.5V213l103.7-71.3L87.7,70.5z"
        />
      </svg>
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        style="enable-background: new 0 0 283.5 283.5"
        xml:space="preserve"
        v-else
      >
        <path
          class="st0"
          d="M239,274.2H44.4C25,274.2,9.2,258.4,9.2,239V44.4C9.2,25,25,9.2,44.4,9.2H239c19.4,0,35.2,15.8,35.2,35.2V239
	C274.2,258.4,258.4,274.2,239,274.2z M44.4,20.6c-13.2,0-23.9,10.7-23.9,23.9V239c0,13.2,10.7,23.9,23.9,23.9H239
	c13.2,0,23.9-10.7,23.9-23.9V44.4c0-13.2-10.7-23.9-23.9-23.9L44.4,20.6L44.4,20.6z"
        />
        <path
          class="st0"
          d="M179,229.5c-3.1,0-5.7-2.5-5.7-5.7v-164c0-3.1,2.5-5.7,5.7-5.7s5.7,2.5,5.7,5.7v164
	C184.7,226.9,182.1,229.5,179,229.5z M110.2,223.8v-164c0-3.1-2.5-5.7-5.7-5.7s-5.7,2.5-5.7,5.7v164c0,3.1,2.5,5.7,5.7,5.7
	S110.2,226.9,110.2,223.8z"
        />
      </svg>
    </span>
    <div v-if="modeOptions[chosenMode].includes(8)" class="csfg__control__range">
      <input type="range" min="0" :max="state.imagesCount - 1" v-model="state.image" />
    </div>
    <span
      :class="[
        'csfg__control__item',
        'csfg__control__item--next',
        { 'csfg__control__item--active': state.image < state.imagesCount - 1 },
      ]"
      v-if="modeOptions[chosenMode].includes(3)"
      v-on:click="changeImage('+')"
    >
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        style="enable-background: new 0 0 283.5 283.5"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M239,274.2H44.4C25,274.2,9.2,258.4,9.2,239V44.4C9.2,25,25,9.2,44.4,9.2H239c19.4,0,35.2,15.8,35.2,35.2V239
	C274.2,258.4,258.4,274.2,239,274.2z M44.4,20.6c-13.2,0-23.9,10.7-23.9,23.9V239c0,13.2,10.7,23.9,23.9,23.9H239
	c13.2,0,23.9-10.7,23.9-23.9V44.4c0-13.2-10.7-23.9-23.9-23.9H44.4z M101,235.3l89.5-89.5c2.2-2.2,2.2-5.8,0-8L101,48.2
	c-2.2-2.2-5.8-2.2-8,0s-2.2,5.8,0,8l85.5,85.5L93,227.2c-2.2,2.2-2.2,5.8,0,8c1.1,1.1,2.6,1.7,4,1.7S99.9,236.4,101,235.3z"
        />
      </svg>
    </span>
    <span
      :class="[
        'csfg__control__item',
        'csfg__control__item--zoom_out',
        { 'csfg__control__item--active': state.zoom > 1 },
      ]"
      v-if="modeOptions[chosenMode].includes(5)"
      v-on:click="state.zoom = state.zoom > 1 ? state.zoom - 1 : 1"
    >
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        style="enable-background: new 0 0 283.5 283.5"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M239,274.2H44.4C25,274.2,9.2,258.4,9.2,239V44.4C9.2,25,25,9.2,44.4,9.2H239c19.4,0,35.2,15.8,35.2,35.2V239
	C274.2,258.4,258.4,274.2,239,274.2z M44.4,20.6c-13.2,0-23.9,10.7-23.9,23.9V239c0,13.2,10.7,23.9,23.9,23.9H239
	c13.2,0,23.9-10.7,23.9-23.9V44.4c0-13.2-10.7-23.9-23.9-23.9H44.4z M244.4,141.8c0-3.1-2.5-5.7-5.7-5.7h-194
	c-3.1,0-5.7,2.5-5.7,5.7s2.5,5.7,5.7,5.7h194C241.8,147.5,244.4,144.9,244.4,141.8z"
        />
      </svg>
    </span>
    <span
      :class="[
        'csfg__control__item',
        'csfg__control__item--zoom_in',
        { 'csfg__control__item--active': state.zoom < 5 },
      ]"
      v-if="modeOptions[chosenMode].includes(4)"
      v-on:click="state.zoom = state.zoom < 5 ? state.zoom + 1 : 5"
    >
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        style="enable-background: new 0 0 283.5 283.5"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M239,9.2H44.4C25,9.2,9.2,25,9.2,44.4V239c0,19.4,15.8,35.2,35.2,35.2H239c19.4,0,35.2-15.8,35.2-35.2V44.4
	C274.2,25,258.4,9.2,239,9.2z M262.8,239c0,13.2-10.7,23.9-23.9,23.9H44.4c-13.2,0-23.9-10.7-23.9-23.9V44.4
	c0-13.2,10.7-23.9,23.9-23.9H239c13.2,0,23.9,10.7,23.9,23.9V239z M244.4,141.8c0,3.1-2.5,5.7-5.7,5.7h-91.3v91.3
	c0,3.1-2.5,5.7-5.7,5.7s-5.7-2.5-5.7-5.7v-91.3H44.7c-3.1,0-5.7-2.5-5.7-5.7s2.5-5.7,5.7-5.7H136V44.8c0-3.1,2.5-5.7,5.7-5.7
	s5.7,2.5,5.7,5.7v91.3h91.3C241.8,136.1,244.4,138.7,244.4,141.8z"
        />
      </svg>
    </span>
    <span
      :class="[
        'csfg__control__item',
        'csfg__control__item--pin',
        { 'csfg__control__item--active': state.pin },
      ]"
      v-if="modeOptions[chosenMode].includes(6)"
      v-on:click="state.pin = !state.pin"
    >
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        style="enable-background: new 0 0 283.5 283.5"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M44.4,9.2H239c19.4,0,35.2,15.8,35.2,35.2V239c0,19.4-15.8,35.2-35.2,35.2H44.4C25,274.2,9.2,258.4,9.2,239
	V44.4C9.2,25,25,9.2,44.4,9.2z M239,262.8c13.2,0,23.9-10.7,23.9-23.9V44.4c0-13.2-10.7-23.9-23.9-23.9H44.4
	c-13.2,0-23.9,10.7-23.9,23.9V239c0,13.2,10.7,23.9,23.9,23.9H239V262.8z M141.8,56.1c18.6,0,36.2,7.3,49.4,20.5
	c13.1,13.1,20.4,30.6,20.4,49.3c0,18.6-7.3,36.2-20.5,49.4l-49.4,49.4l-49.4-49.4c-13.2-13.2-20.5-30.7-20.5-49.4
	c0-18.6,7.3-36.2,20.5-49.4C105.5,63.4,123.1,56.1,141.8,56.1 M141.8,174c26.5,0,48.1-21.6,48.1-48.1s-21.6-48.1-48.1-48.1
	s-48.1,21.6-48.1,48.1S115.3,174,141.8,174 M141.8,44.8c-21.7,0-42.1,8.5-57.5,23.8C69,83.9,60.5,104.2,60.5,125.9s8.5,42,23.8,57.4
	l54.1,54.1c0.9,0.9,2.2,1.4,3.3,1.4s2.4-0.4,3.3-1.4l54.1-54.1c15.3-15.3,23.8-35.7,23.8-57.4c0-21.6-8.4-42.1-23.7-57.4
	C183.8,53.2,163.4,44.8,141.8,44.8L141.8,44.8z M141.8,162.6c-20.3,0-36.7-16.5-36.7-36.7s16.5-36.7,36.7-36.7s36.7,16.5,36.7,36.7
	S162,162.6,141.8,162.6L141.8,162.6z"
        />
      </svg>
    </span>
    <span
      :class="[
        'csfg__control__item',
        'csfg__control__item--fullscreen',
        { 'csfg__control__item--active': state.fullscreen },
      ]"
      v-if="modeOptions[chosenMode].includes(7)"
      v-on:click="state.fullscreen = !state.fullscreen"
    >
      <svg
        version="1.1"
        id="Warstwa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        style="enable-background: new 0 0 283.5 283.5"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M239,9.2H44.4C25,9.2,9.2,25,9.2,44.4V239c0,19.4,15.8,35.2,35.2,35.2H239c19.4,0,35.2-15.8,35.2-35.2V44.4
	C274.2,25,258.4,9.2,239,9.2z M262.8,239c0,13.2-10.7,23.9-23.9,23.9H44.4c-13.2,0-23.9-10.7-23.9-23.9V44.4
	c0-13.2,10.7-23.9,23.9-23.9H239c13.2,0,23.9,10.7,23.9,23.9V239z M108.4,100.4c2.2,2.2,2.2,5.8,0,8c-1.1,1.1-2.6,1.7-4,1.7
	s-2.9-0.6-4-1.7L50.5,58.5v31c0,3.1-2.5,5.7-5.7,5.7s-5.7-2.5-5.7-5.7V44.8c0-0.4,0-0.7,0.1-1c0,0,0,0,0-0.1c0.1-0.3,0.2-0.7,0.3-1
	c0,0,0,0,0-0.1c0.1-0.3,0.3-0.6,0.5-0.9c0,0,0,0,0-0.1c0.4-0.6,1-1.2,1.6-1.6c0,0,0,0,0.1,0c0.3-0.2,0.6-0.4,0.9-0.5c0,0,0,0,0.1,0
	c0.3-0.1,0.6-0.2,1-0.3c0,0,0,0,0.1,0c0.3-0.1,0.7-0.1,1-0.1h44.7c3.1,0,5.7,2.5,5.7,5.7s-2.5,5.7-5.7,5.7h-31L108.4,100.4z
M108.4,175.1c2.2,2.2,2.2,5.8,0,8L58.5,233h31c3.1,0,5.7,2.5,5.7,5.7s-2.5,5.7-5.7,5.7H44.8c-0.4,0-0.7,0-1-0.1c0,0,0,0-0.1,0
	c-0.3-0.1-0.7-0.2-1-0.3c0,0-0.1,0-0.1,0c-0.3-0.1-0.6-0.3-0.9-0.5c0,0,0,0-0.1,0c-0.3-0.2-0.6-0.4-0.9-0.7c0,0,0,0,0,0c0,0,0,0,0,0
	c-0.3-0.3-0.5-0.6-0.7-0.9c0,0,0,0,0-0.1c-0.2-0.3-0.4-0.6-0.5-0.9c0,0,0,0,0-0.1c-0.1-0.3-0.2-0.6-0.3-1c0,0,0,0,0-0.1
	c-0.1-0.3-0.1-0.7-0.1-1V194c0-3.1,2.5-5.7,5.7-5.7s5.7,2.5,5.7,5.7v31l49.9-49.9C102.6,172.9,106.2,172.9,108.4,175.1z
M243.4,241.8c-0.2,0.3-0.4,0.6-0.7,0.9c0,0,0,0,0,0s0,0,0,0c-0.3,0.3-0.6,0.5-0.9,0.7c0,0,0,0-0.1,0c-0.3,0.2-0.6,0.3-0.9,0.5
	c0,0-0.1,0-0.1,0c-0.3,0.1-0.6,0.2-1,0.3c0,0,0,0-0.1,0c-0.3,0.1-0.7,0.1-1,0.1H194c-3.1,0-5.7-2.5-5.7-5.7s2.5-5.7,5.7-5.7h31
	l-49.9-49.9c-2.2-2.2-2.2-5.8,0-8c2.2-2.2,5.8-2.2,8,0L233,225v-31c0-3.1,2.5-5.7,5.7-5.7s5.7,2.5,5.7,5.7v44.7c0,0,0,0,0,0
	c0,0.4,0,0.7-0.1,1c0,0,0,0,0,0.1c-0.1,0.3-0.2,0.7-0.3,1c0,0,0,0.1,0,0.1C243.8,241.2,243.6,241.5,243.4,241.8
	C243.4,241.8,243.4,241.8,243.4,241.8z M244.4,44.8C244.4,44.8,244.4,44.8,244.4,44.8l0,44.7c0,3.1-2.5,5.7-5.7,5.7
	s-5.7-2.5-5.7-5.7v-31l-49.9,49.9c-1.1,1.1-2.6,1.7-4,1.7s-2.9-0.6-4-1.7c-2.2-2.2-2.2-5.8,0-8L225,50.5h-31c-3.1,0-5.7-2.5-5.7-5.7
	s2.5-5.7,5.7-5.7h44.7c0.4,0,0.7,0,1,0.1c0,0,0,0,0,0c0.3,0.1,0.7,0.2,1,0.3c0,0,0,0,0.1,0c0.3,0.1,0.6,0.3,0.9,0.5c0,0,0,0,0.1,0
	c0.3,0.2,0.6,0.4,0.9,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.3,0.5,0.6,0.7,0.9c0,0,0,0,0,0.1c0.2,0.3,0.3,0.6,0.5,0.9c0,0,0,0.1,0,0.1
	c0.1,0.3,0.2,0.6,0.3,1c0,0,0,0,0,0.1C244.3,44.1,244.4,44.4,244.4,44.8z"
        />
      </svg>
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      modeOptions: {
        1: [1, 2, 3, 4, 5, 6, 7],
        2: [2, 6, 7, 8],
        3: [1, 2, 3, 4, 5, 6, 7],
        4: [1, 2, 3, 4, 5, 6, 7],
        5: [1, 2, 3, 4, 5, 6, 7],
      },
      state: null,
      autoplayer: null,
    };
  },
  props: ["chosenMode", "active", "stateOptions"],
  watch: {
    state: {
      handler() {
        this.$emit("changeState", this.state);
        this.autoPlay();
      },
      deep: true,
    },
    stateOptions: {
      handler() {
        this.state = false;
        this.state = this.stateOptions;
      },
      deep: true,
    },
  },
  methods: {
    changeImage(mode) {
      switch (mode) {
        case "+":
          this.state = {
            ...this.state,
            image:
              this.state.image < this.state.imagesCount - 1
                ? this.state.image + 1
                : this.state.imagesCount - 1,
          };
          break;

        default:
          this.state = {
            ...this.state,
            image: this.state.image > 0 ? this.state.image - 1 : 0,
          };
          break;
      }
    },
    autoPlay() {
      if (this.state && this.state.play === true) {
        clearInterval(this.autoplayer);
        this.autoplayer = setInterval(() => {
          this.state.image < this.state.imagesCount - 1
            ? this.changeImage("+")
            : (this.state.image = 0);
        }, 500);
        return;
      }
      clearInterval(this.autoplayer);
    },
  },
  mounted() {
    this.autoPlay();

    document.addEventListener("keydown", (e) => {
      e = e || window.event;

      switch (e.key) {
        case "ArrowLeft":
          this.changeImage("-");
          break;
        case "ArrowRight":
          this.changeImage("+");
          break;
        case "ArrowUp":
          // Up pressed
          break;
        case "ArrowDown":
        // Down pressed
      }
    });
  },
};
</script>

<style lang="scss">
.csfg__control {
  opacity: 0;
  transition: opacity 1s;
  @media (min-width: 780px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 780px) {
    padding: 1rem 0;
    margin: 0 auto;
    display: inline-flex;
  }
  &--active {
    opacity: 1;
  }
  &__item {
    margin: 0.25rem;
    cursor: pointer;
    line-height: 0;
    svg {
      width: 30px;
      fill: #b5b5b5;
      transition: fill 0.25s;
    }
    &--play,
    &--active {
      svg {
        fill: #1386b8;
      }
    }
    &--zoom_in,
    &--zoom_out {
      fill: #b5b5b5;
    }
    &:hover {
      svg {
        fill: #810d60;
      }
    }
  }
  &__range {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 780px) {
      min-width: 200px;
    }
    padding: 0.5rem;
    input {
      -webkit-appearance: none; /* Override default CSS styles */
      appearance: none;
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 2px;
      background-color: #e2e2e2;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: #810d60;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
  &--mode2 {
    width: 100%;
    justify-content: space-between;
    .csfg__control__range {
      width: 100%;
      max-width: auto;
    }
  }
}
</style>
