<template>
  <div class="c-pins" v-if="pins">
    <div
      class="c-pins__pin"
      v-for="pin in pins"
      :key="pin"
      :style="`left:${pin.px}%;top:${pin.py}%;`"
      v-on:click="
        selected = null;
        selected = pin ? pin : null;
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="35.082"
        height="39"
        viewBox="0 0 35.082 39"
        class="c-pins__pin__icon"
        :class="{
          'c-pins__pin__icon--green': pin.type.is_positive == 1,
          'c-pins__pin__icon--red': pin.type.is_positive == 0,
          'c-pins__pin__icon--green--onClick':
            selected == pin && pin.type.is_positive == 1,
          'c-pins__pin__icon--red--onClick': selected == pin && pin.type.is_positive == 0,
        }"
      >
        <defs>
          <filter
            id="pin"
            x="0"
            y="0"
            width="35.082"
            height="39"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="2.5" result="blur" />
            <feFlood flood-opacity="0.502" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#pin)">
          <path
            id="pin-2"
            data-name="pin"
            d="M60.949,28.244A10.041,10.041,0,1,0,46.744,42.438l6.687,6.687a.593.593,0,0,0,.41.174.552.552,0,0,0,.41-.174l6.687-6.687a10.041,10.041,0,0,0,.01-14.195Zm-7.1,11.641a4.544,4.544,0,1,1,4.544-4.544A4.546,4.546,0,0,1,53.851,39.885Z"
            transform="translate(-36.3 -17.8)"
          />
        </g>
      </svg>
    </div>
    <div class="c-pins__message" v-if="selected">
      <span class="c-pins__message__close" v-on:click="selected = null">X </span>
      <span
        class="c-pins__message__header"
        :class="{
          'c-pins__message__header--positive': selected.type.is_positive == 1,
          'c-pins__message__header--negative': selected.type.is_positive == 0,
        }"
      >
        <span>{{ selected.type.name }}</span>
      </span>
      <br />

      <div class="c-pins__content">
        <span class="c-pins__content__desciption">{{ selected.description }}</span>
        <span class="c-pins__content__image"> <img :src="selected.image" /> </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pins: [],
      selected: null,
    };
  },
  props: {
    img: { default: {} },
    imageIndex: { default: null },
  },
  methods: {
    setPinsPositions() {
      this.pins = this.img.pins.map((e) => {
        return {
          ...e,
          px: e.x * 100,
          py: e.y * 100,
        };
      });
      // var that = this;
      // const img = new Image();
      // img.src = this.img.file;
      // img.addEventListener("load", function () {
      //   var renderedImages = document.querySelectorAll(".csfg__slider__image__wrap img");
      //   var scaleRatio = this.naturalWidth / renderedImages[that.imageIndex].width;
      //   console.log("nw", this.naturalWidth, "rw", renderedImages[that.imageIndex].width);
      //   that.pins = that.img.pins.map((e) => {
      //     return {
      //       ...e,
      //       px: e.x * scaleRatio,
      //       py: e.y * scaleRatio,
      //       // px: (e.x / this.naturalWidth) * 100,
      //       // py: (e.y / this.naturalHeight) * 100,
      //     };
      //   });
      //   console.log(that.pins);
      // });
    },
  },
  mounted() {
    setTimeout(this.setPinsPositions, 1000);
    window.addEventListener("resize", this.setPinsPositions);
  },
};
</script>

<style lang="scss">
.c-pins {
  height: 100%;
  z-index: 9999;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: inherit;
  margin: 0 auto;
  &__content {
    width: 100%;
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    &__image {
      opacity: 1;
      min-width: 150px;
      max-width: 200px;
      height: auto;
      img {
        border-radius: 12px;
      }
    }
    &__desciption {
      color: #000;
      display: flex;
      align-items: center;
      padding-right: 10px;
    }
  }
  &__pin {
    position: absolute;
    transform: translate(15px, -15px);
    cursor: pointer;
    &__icon {
      fill: #fff;
      z-index: 9999999;
      &--green {
        :hover {
          fill: #68d578;
        }
        &--onClick {
          fill: #68d578;
        }
      }
      &--red {
        :hover {
          fill: #d53535;
        }
        &--onClick {
          fill: #d53535;
        }
      }
    }
  }
  &__message {
    /* UI Properties */
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    opacity: 0.95;
    position: relative;
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    bottom: calc(-100% + 150px);
    padding: 0 20px 20px 20px;
    border-radius: 20px;
    z-index: 99999999;
    &__close {
      font-size: 0.8rem;
      background-color: #810d60;
      color: #fff;
      border-radius: 50%;
      position: absolute;
      right: -5px;
      top: -5px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    &__header {
      color: #fff;
      padding: 10px 20px 10px 20px;
      border-radius: 20px;
      position: relative;
      top: -10px;
      &--positive {
        background-color: #68d578;
      }
      &--negative {
        background-color: #d53535;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    .c-pins {
      &__message {
        width: 80vw;
        font-size: 16px;
      }
    }
  }
}
</style>
