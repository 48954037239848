<template>
  <div :class="['csfg__main', { 'csfg__main--fullcereen': stateOptions.fullscreen }]">
    <div class="csfg__main__wrap">
      <div class="csfg__main__stage">
        <NewLoading v-if="loading" :precentage="loadedImagesPrecentage" />
        <NewSLider
          v-if="loadedImagesPrecentage === 100 && !loading"
          :images="filtredGallery"
          :stateOptions="stateOptions"
        />
      </div>
      <div class="csfg__main__nav">
        <span>
          <img
            src="https://player.carsalesgallery.com/icons/csg_logo_app_v2_light_mode.svg"
            alt=""
          />
        </span>
        <NewNavbar :chosenMode="selectedMode" @choseMode="choseMode($event)" />
        <NewControl
          :chosenMode="selectedMode"
          :active="!loading"
          :stateOptions="stateOptions"
          @changeState="stateOptions = $event"
        />
      </div>
    </div>
  </div>
</template>
<script>
import NewNavbar from "@/components/NewNavbar";
import NewLoading from "@/components/NewLoading";
import NewControl from "@/components/NewControl";
import NewSLider from "@/components/NewSlider";
import axios from "axios";
export default {
  components: {
    NewNavbar,
    NewLoading,
    NewControl,
    NewSLider,
  },
  data() {
    return {
      loading: true,
      loadedImages: 0,
      pictureTypes: false,
      pinsTypes: false,
      gallery: [],
      selectedMode: 1,
      selectedImage: 0,
      stateOptions: {
        image: 0,
        imagesCount: 0,
        play: false,
        zoom: 1,
        pin: false,
        fullscreen: false,
      },
    };
  },
  computed: {
    filtredGallery() {
      return this.gallery.filter((e) => this.selectedMode == e.type_id);
    },
    loadedImagesPrecentage() {
      return Math.round((this.loadedImages / this.filtredGallery.length) * 100);
    },
  },
  methods: {
    choseMode(value) {
      this.selectedMode = value;
      this.loading = true;

      this.stateOptions = {
        image: 0,
        imagesCount: this.filtredGallery.length,
        play: false,
        zoom: 1,
        pin: false,
        fullscreen: false,
      };

      this.loadedImages = 0;
      this.filtredGallery.forEach((e) => {
        const image = new Image();
        image.src = e.file;
        image.onload = () => {
          this.loadedImages++;
          if (this.loadedImages === this.filtredGallery.length) {
            setTimeout((this.loading = false), 1500);
          }
        };
      });
    },
    async fetchPictureTypes() {
      const response = await axios.get(
        "https://app.carsalesgallery.com/public-api/picturesTypes/list"
      );
      this.pictureTypes = response.data.picturesTypes;
    },
    async fetchPinTpyes() {
      const response = await axios.get(
        "https://app.carsalesgallery.com/public-api/pinsTypes/list"
      );
      this.pinsTypes = response.data.pinsTypes;
    },
    async fetchGallery(params) {
      const response = await axios.get(
        "https://app.carsalesgallery.com/public-api/galleries/get",
        {
          params,
        }
      );
      this.gallery = response.data.gallery.pictures.map((e) => {
        return {
          ...e,
          pins: e.pins.map((p) => {
            return {
              ...p,
              type: this.pinsTypes ? this.pinsTypes.find((t) => t.id == p.type_id) : null,
            };
          }),
          type: this.pictureTypes.find((t) => t.id == e.type_id),
        };
      });
      return response;
    },
  },
  created() {
    this.fetchPictureTypes();
    this.fetchPinTpyes();
  },
  mounted() {
    var scriptWidget = document.getElementById("csfgScript");
    // test mode. Please remove on Production
    // var dataId = 235;
    // var dataToken = "$2y$10$hOa2Zk.6fbLudfrvMmaNI.zgPt6VzoJInXH7.qfaHZ.DlCfVVi79S";

    if (scriptWidget) {
      let dataId = scriptWidget.getAttribute("data-id");
      let dataToken = scriptWidget.getAttribute("data-token");
      this.fetchGallery({ id: dataId, token: dataToken }).then(() => {
        this.choseMode(1);
      });
    }
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Black.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Black.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Black.woff2") format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Black.woff") format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-BlackItalic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-BlackItalic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-BlackItalic.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-BlackItalic.ttf")
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Bold.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Bold.woff") format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Medium.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Medium.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Medium.woff") format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Light.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Light.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Light.woff2") format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Light.woff") format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBoldItalic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBoldItalic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBoldItalic.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-MediumItalic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-MediumItalic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-MediumItalic.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-MediumItalic.ttf")
      format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Thin.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Thin.woff2") format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Thin.woff") format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-LightItalic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-LightItalic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-LightItalic.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-LightItalic.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBold.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBold.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBold.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-BoldItalic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-BoldItalic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-BoldItalic.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-BoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Regular.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Regular.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Regular.woff") format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLightItalic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLightItalic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLightItalic.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLightItalic.ttf")
      format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBoldItalic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBoldItalic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBoldItalic.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-SemiBoldItalic.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBold.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBold.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBold.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraBold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Italic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Italic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Italic.woff") format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-Italic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLight.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLight.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLight.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ExtraLight.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ThinItalic.eot");
  src: url("https://player.carsalesgallery.com/fonts/Montserrat-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ThinItalic.woff2")
      format("woff2"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ThinItalic.woff")
      format("woff"),
    url("https://player.carsalesgallery.com/fonts/Montserrat-ThinItalic.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

.csfg__main {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999;
  font-family: "Montserrat", Arial, Sans-serif;
  font-size: 11px;
  @media (min-width: 900px) {
    font-size: 12.34px;
  }
  @media (min-width: 1100px) {
    font-size: 16px;
  }
  * {
    box-sizing: border-box;
  }
  &__wrap {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__stage {
    position: relative;
    border: 2px solid #d1d1d1;
    background-color: #eee;
    width: 100%;
    min-height: 320px;
    transition: height 1s;
  }
  &__nav {
    position: relative;
    width: 100%;
    padding: 10px;
    border: 2px solid #d1d1d1;
    background-color: #fff;
    border-top: 0;
    border-radius: 0 0 10px 10px;
    @media (min-width: 780px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 5%;
    }
    @media (max-width: 780px) {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
  transition: background-color 1s;
  &--fullcereen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.557);
    padding: 2%;
    .csfg__main__wrap {
      box-shadow: 0px 0px 50px -24px rgba(0, 0, 0, 1);
    }
  }
}
</style>
