<template>
  <div
    :class="['csfg__slider__image', { 'csfg__slider__image--active': active }]"
    v-if="img && img.file"
  >
    <div
      v-on:mousemove="mousemove($event)"
      ref="main"
      class="csfg__slider__image__move"
      :style="`height:${clientHeight}px;width:${clientWidth}px;`"
    >
      <NewPinsComp
        v-if="showPins && zoom === 1"
        class="pins"
        :img="img"
        :imageIndex="imageIndex"
      />
    </div>
    <span :style="zoomStyle" class="csfg__slider__image__wrap">
      <img :src="img.file" ref="image" />
    </span>
  </div>
</template>
<script>
import NewPinsComp from "@/components/NewPinsComp.vue";
export default {
  data() {
    return {
      clientHeight: 0,
      clientWidth: 0,
      zoomProperties: {
        x: 1,
        y: 1,
        z: 1,
      },
    };
  },
  props: {
    img: { default: null },
    imageIndex: { default: null },
    zoom: { default: 1 },
    showPins: { default: true },
    active: { default: false },
  },
  computed: {
    zoomStyle() {
      return this.zoom > 1
        ? `left:${this.zoomProperties.x}px;top:${this.zoomProperties.y}px;transform:scale(${this.zoomProperties.z});height:${this.clientHeight}px;width:${this.clientWidth}px;`
        : null;
    },
  },
  watch: {
    zoom() {
      this.zoomProperties.z = this.zoom;
      if (this.zoom == 1) return 0;
    },
  },
  methods: {
    mousemove(r) {
      let x =
        this.$refs.main.clientWidth -
        (r.offsetX / this.$refs.main.clientWidth) * (this.$refs.image.clientWidth * 2);
      let y =
        this.$refs.main.clientHeight -
        (r.offsetY / this.$refs.main.clientHeight) * (this.$refs.image.clientHeight * 2);

      this.zoomProperties = {
        ...this.zoomProperties,
        x,
        y,
      };
    },
    getImageDimensions() {
      if (this.$refs.image.clientHeight != 0) {
        this.clientHeight = this.$refs.image.clientHeight;
      }
      if (this.$refs.image.clientWidth != 0) {
        this.clientWidth = this.$refs.image.clientWidth;
      }
    },
  },
  mounted() {
    setTimeout(() => this.getImageDimensions(), 500);
    window.addEventListener("resize", () => {
      this.getImageDimensions();
    });
  },
  components: {
    NewPinsComp,
  },
};
</script>

<style lang="scss">
.csfg__slider__image {
  background: #eee;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  overflow: hidden;
  z-index: 887;
  img {
    max-width: 100%;
    max-height: 80vh;
    opacity: 0;
    // transition: opacity 0.2s;
    line-height: 0;
  }
  &--active {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    z-index: 888;
    img {
      opacity: 1;
    }
  }
  &__wrap {
    position: absolute;
    transition: transform 0.2s;
  }
  &--zoom {
    position: relative;
    width: 100%;
    min-height: 500px;
    overflow: hidden;
  }
  &__move {
    z-index: 888;
    height: 1px;
    transition: height 1s;
  }
  &__cords {
  }
}
</style>
