<template>
  <div class="csfg__slider" v-if="stateOptions">
    <NewSliderImg
      v-for="(image, key) in images"
      :key="image.id"
      :img="image"
      :imageIndex="key"
      :zoom="stateOptions.zoom"
      :active="key == stateOptions.image"
      :showPins="stateOptions.pin"
    />
  </div>
</template>
<script>
import NewSliderImg from "@/components/NewSliderImg.vue";

export default {
  components: { NewSliderImg },
  props: {
    images: {
      default: [],
    },
    stateOptions: {
      default: false,
    },
  },
};
</script>
<style lang="scss">
.csfg__slider {
  position: relative;
  width: 100%;
}
</style>
