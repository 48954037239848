export const zoomValues = [1, 1.4, 1.8, 2.2, 2.5];
export const navigation = [
  {
    name: "8X",
    action: 1,
  },
  // {
  //   name: "360&#176; OUTside",
  //   action: 2,
  // },
  {
    name: "Details OUTside",
    action: 3,
  },
  // {
  //   name: "360&#176; INside",
  //   action: 4,
  // },
  {
    name: "Details INside",
    action: 4,
  },
  {
    name: "Inspekcja",
    action: 5,
  },
]