<template>
  <div class="csfg__loading">
    <span>
      <img src="https://player.carsalesgallery.com/icons/loader.svg" alt="Ładowanie..." />
      <span class="csfg__loading__precentage">{{ precentage }}%</span>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    precentage: {
      default: "",
    },
  },
};
</script>
<style lang="scss">
.csfg__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  z-index: 9999;
  img {
    animation: loading 1s linear infinite;
    image-rendering: pixelated;
    width: 75px;
    height: 75px;
  }
  &__precentage {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 12px;
    font-weight: bold;
    transform: translate(-50%, -50%);
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(90deg) scale(1.01);
  }
  100% {
    transform: rotate(180deg) scale(1);
  }
}
</style>
